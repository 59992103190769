<template>
  <div style="text-align: left; min-width: 410px">
    <div
      class="my-12 pb-12 pt-6"
      style="
        width: 85%;
        max-width: 1100px;
        margin: auto;
        font-family: 'Noto Sans JP', sans-serif;
        line-height: 45px;
        font-style: normal;
        font-size: 20px;
      "
    >
      <div v-if="$vuetify.breakpoint.mdAndUp" class="mb-4">
        <div style="text-align: center; min-width: 700px">
          <span style="line-height: 212px; min-width: 700px; font-size: 55px; font-weight: 900">特定商取引法に基づく表記</span>
        </div>
        <div
          style="
            text-align: center;
            position: absolute;
            top: 30px;
            left: 0;
            right: 0;
            width: 1000px;
            height: 200px;
            margin: auto;
          "
        >
          <span style="font-family: Helvetica; line-height: 300px; font-size: 150px; font-weight: bold; opacity: 0.08">INFORMATION</span>
        </div>
      </div>
      <div v-if="!$vuetify.breakpoint.mdAndUp" class="mb-1">
        <div style="text-align: center; width: 100%; min-width: 370px">
          <span style="line-height: 100px; min-width: 370px; font-size: 30px; font-weight: 900">特定商取引法に基づく表記</span>
        </div>
        <div
          style="
            text-align: center;
            position: absolute;
            top: -35px;
            left: 0;
            right: 0;
            width: 100%;

            height: 282px;
            margin: auto;
          "
        >
          <span style="font-family: Helvetica; line-height: 300px; font-size: 50px; font-weight: bold; opacity: 0.08">INFORMATION</span>
        </div>
      </div>
      <table v-if="$vuetify.breakpoint.mdAndUp" style="width: 100%; font-size: 15px" cellspacing="0">
        <tr v-for="(item, index) in form" :key="index">
          <th style="width: 30%; font-weight: normal" class="py-6 px-4">
            {{ item.title }}
          </th>
          <td style="width: 70%" class="py-6 px-3">
            <span v-if="index !== 8">{{ item.text1 }}</span>
            <span v-if="index === 8">送料 →
              <span class="pointer"><span style="color: #ff6e40; text-decoration: underline" @click="dialog = true">送料一覧表</span></span>をご確認ください。</span>

            <br v-if="item.text2" />
            {{ item.text2 }}
            <br v-if="item.text3" />
            {{ item.text3 }}
            <br v-if="item.text4" />
            {{ item.text4 }}
          </td>
        </tr>
      </table>
      <!-- <table v-if="!$vuetify.breakpoint.mdAndUp" style="width: 100%; font-size: 13px" cellspacing="0">
        <tr v-for="(item, index) in form" :key="index">
          <th style="width: 30%; font-weight: normal" class="py-3 px-3">
            {{ item.title }}
          </th>
          <td style="width: 70%" class="py-3 px-3">
            {{ item.text1 }}
            <br v-if="index !== 2 && index !== 3 && index !== 4" />
            {{ item.text2 }}
            <br v-if="index !== 2 && index !== 3 && index !== 4" />
            {{ item.text3 }}
            <br v-if="index !== 0 && index !== 2 && index !== 3 && index !== 4" />
            {{ item.text4 }}
          </td>
        </tr>
      </table> -->
      <Tip :dialog="dialog" @closeDialog="closeDialog"></Tip>
    </div>
  </div>
</template>

<script>
import Tip from './ShippingFee.vue'

export default {
  components: {
    Tip,
  },
  data: () => ({
    dialog: false,
    form: [
      {
        title: '販売業者',
        text1: '有限会社ORIENTAL DELI',
      },
      {
        title: '販売責任者',
        text1: '代表取締役　太齋秀明',
      },
      {
        title: '設立',
        text1: '2006年4月20日',
      },
      {
        title: '所在地',
        text1: '〒108-0071',
        text2: '東京都港区白金台5-2-23田渕ビル1F',
      },
      {
        title: '電話番号',
        text1: '03-5447-1373',
      },
      {
        title: 'FAX番号',
        text1: '03-5447-1374',
      },
      {
        title: 'メールアドレス',
        text1: 'info@bimistock.com',
      },
      {
        title: 'サービス代金',
        text1: '各商品の紹介ページに記載している価格となります。',
      },
      {
        title: 'サービス代金以外に必要な費用',
        text1: '送料　→　送料一覧表をご確認ください。',
      },
      {
        title: '注文方法',
        text1: '弊社ホームページからのオンライン注文',
      },
      {
        title: 'お支払い方法',
        text1: 'クレジットカード',
        text2: '※ご利用いただけるクレジットカード：VISA／Master／JCB／アメリカン・エキスプレス／ダイナース',
      },
      {
        title: '配送について',
        text1: '佐川急便',
      },
      {
        title: '商品のお届け時期',
        text1: '・お試しセット：クレジットカード決済後7営業日以内に発送致します。',
        text2:
          '・定期便：初回便はクレジットカード決済後7営業日以内に以内に発送致します。その後は、前回の発送時から3か月後毎に発送致します。',
        text3:
          '・何らかの理由でクレジットカードの決済が完了しない場合には発送が遅れる可能性がございます。また、交通事情等により商品の発送が遅れる可能性もございます。',
      },
      {
        title: 'お試しセットのご注文のキャンセル、及び定期便の停止・解約、内容の変更',
        text1:
          '・万一、お試しセットのご注文をキャンセルされたい場合は、決済前までは『BimiStock』ホームページのマイページ内（「注文履歴」→「未決済」タブ）にてお手続きができます。※決済はご購入のお手続き日の翌々日にご指定のクレジットカードにて代金決済となります。決済が完了した後は、『BimiStock』ホームページのマイページ内でのキャンセルができません。決済日から発送日前までにキャンセルをご希望の場合は、カスタマーデスク（info@bimistock.com）宛にメールにてご連絡ください。但し、発送後のキャンセルはお受けできませんので、予めご了承ください。',
        text2:
          '・定期便を一時停止されたい場合は、『BimiStock』ホームページのマイページの「定期便」ページ内にある、「配送スケジュール」画面にて「スキップ」ボタンを押してお手続きください。',
        text3:
          '・定期便を一時停止されたい場合は、『BimiStock』ホームページのマイページの「定期便」ページ内にある、「各種設定」画面にて「配送中止」ボタンを押してお手続きください。',
        text4:
          '・定期便のお届け先の変更をされたい場合は、『BimiStock』ホームページのマイページの「お届け先」ページにて変更・削除・追加をお手続きください。',
      },
      {
        title: '代金のお支払い（決済）時期',
        text1: 'ご購入のお手続き日の翌々日にご指定のクレジットカードにて代金決済となります。',
      },
      {
        title: '会員情報の変更',
        text1:
          '・会員情報を変更されたい場合は、『BimiStock』ホームページのマイページの「会員情報」ページにて、住所、電話番号、パスワードの変更をすることができます。Eメールはシステム内で変更することができませんので、もし、その必要が生じた場合は、カスタマーデスク（info@bimistock.com）宛にメールにてご連絡ください。',
      },
      {
        title: 'クレジットカードの変更',
        text1:
          'クレジットカードを変更されたい場合は、『BimiStock』ホームページのマイページの「支払い情報」ページにてお手続きください。',
      },
    ],
  }),
  methods: {
    closeDialog() {
      this.dialog = false
    },
  },
}
</script>

<style scoped>
table {
  border-top: 1px solid #cbcbcb;
  min-width: 300px;
}
th,
td {
  border: 1px solid #cbcbcb;
}
td {
  border-left: 0;
  border-top: 0;
}
th {
  border-top: 0;
  background-color: #ebebeb;
}
.pointer :hover {
  cursor: pointer;
}
</style>
